import {AxiosPromise} from "axios";
import Api, {PostHeaders} from "./config/ApiConfig";
import {LinkApiResource} from "./AuthApi";

export function GetPosts(params: PostSearchCriteriaApiResource): AxiosPromise<PostSummaryPageApiResource> {
    return Api.request({method: 'GET', url: uriEncoding`/v1/posts`, params: params});
}

export function GetPost(postId: string): AxiosPromise<PostApiResource> {
    return Api.request({method: 'GET', url: uriEncoding`/v1/post/${postId}`});
}

export function GetAdminPosts(params: PostSearchCriteriaApiResource): AxiosPromise<PostSummaryPageApiResource> {
    return Api.request({method: 'GET', url: uriEncoding`/v1/admin/posts`, params: params});
}

export function GetAdminPost(postId: string): AxiosPromise<PostApiResource> {
    return Api.request({method: 'GET', url: uriEncoding`/v1/admin/post/${postId}`});
}

export function NewPost(data: PostApiResource, csrfToken: string): AxiosPromise<PostApiResource> {
    return Api.request({method: 'POST', url: uriEncoding`/v1/admin/post`, headers: PostHeaders(csrfToken), data: data});
}

export function EditPost(data: PostApiResource, csrfToken: string): AxiosPromise<PostApiResource> {
    return Api.request({method: 'PUT', url: uriEncoding`/v1/admin/post/${data.postId}`, headers: PostHeaders(csrfToken), data: data});
}

export function DeletePost(postId: string, csrfToken: string): AxiosPromise {
    return Api.request({method: 'DELETE', url: uriEncoding`/v1/admin/post/${postId}`, headers: PostHeaders(csrfToken)});
}

export function SaveDraft(data: PostApiResource, csrfToken: string): AxiosPromise<PostApiResource> {
    return Api.request({method: 'POST', url: uriEncoding`/v1/admin/post/${data.postId}/draft`, headers: PostHeaders(csrfToken), data: data});
}

export function DiscardDraft(postId: string, csrfToken: string): AxiosPromise<PostApiResource> {
    return Api.request({method: 'DELETE', url: uriEncoding`/v1/admin/post/${postId}/draft`, headers: PostHeaders(csrfToken)});
}

export class PostSearchCriteriaApiResource {
    page: number;
    size: number;

    constructor(data: PostSearchCriteriaApiResource) {
        this.page = data.page;
        this.size = data.size;
    }
}

export class PostSummaryPageApiResource {
    posts: PostSummaryApiResource[];
    links: LinkApiResource[];

    constructor(data: PostSummaryPageApiResource) {
        this.posts = data.posts;
        this.links = data.links;
    }
}

export class PostSummaryApiResource {
    postId: string;
    postDate: Date;
    title: string;
    summary: string;
    image: string;
    imageAlt: string;
    draft?: boolean;
    scheduled?: boolean;
    scheduledDate?: Date;

    constructor(data: PostSummaryApiResource) {
        this.postId = data.postId;
        this.postDate = data.postDate;
        this.title = data.title;
        this.summary = data.summary;
        this.image = data.image;
        this.imageAlt = data.imageAlt;
        this.draft = data.draft;
        this.scheduled = data.scheduled;
        this.scheduledDate = data.scheduledDate;
    }
}

export class PostApiResource {
    postId?: string;
    author?: string;
    draft?: boolean;
    scheduled?: boolean;
    scheduledDate?: Date;
    postDate?: Date;
    editedDate?: Date;
    title: string;
    description: string;
    summaryImage: string;
    summaryImageAlt: string;
    content: string;
    tags: string;
    fromDraft?: boolean;

    constructor(data: PostApiResource) {
        this.postId = data.postId;
        this.author = data.author;
        this.draft = data.draft;
        this.scheduled = data.scheduled;
        this.scheduledDate = data.scheduledDate;
        this.postDate = data.postDate;
        this.editedDate = data.editedDate;
        this.title = data.title;
        this.description = data.description;
        this.summaryImage = data.summaryImage;
        this.summaryImageAlt = data.summaryImageAlt;
        this.content = data.content;
        this.tags = data.tags;
        this.fromDraft = data.fromDraft;
    }
}

function uriEncoding(template: TemplateStringsArray, ...substitutions: any[]): string {
    let result = '';
    for (let i = 0; i < substitutions.length; i++) {
        result += template[i];
        result += encodeURIComponent(substitutions[i]);
    }
    result += template[template.length - 1];
    return result;
}