export default function Tags({tags}: {tags: string}) {
    const whitespaceRegex = /\s/gi;


    return (
        <div className="postTags">
            {tags.split(',').map((tag, i) => {
                return <a href={"https://www.google.com/search?q=" + encodeURI(tag.trim().replace(whitespaceRegex, '+'))} key={i}>{tag}</a>
            })}
        </div>
    );
}