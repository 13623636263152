import {Navigate, Route, Routes} from "react-router-dom";
import Layout from "./layout";
import Posts from "../posts/posts";
import Post from "../posts/post";
import AdminPost from "../posts/admin/post";
import AdminPosts from "../posts/admin/posts";
import NotFound from "../components/notFound";
import SecureResource from "../auth/secure-resource";
import Logout from "../auth/logout";

export default function App() {
    return (
        <Routes>
            <Route path="/" element={<Layout />}>
                <Route index element={<Posts />} />
                <Route path="/posts/:postId" element={<Post />} />
                <Route path="/admin" element={<SecureResource />}>
                    <Route path="/admin/posts" element={<AdminPosts />} />
                    <Route path="/admin/post" element={<AdminPost />} />
                    <Route path="/admin/post/:postId" element={<AdminPost />} />
                    <Route path="/admin/logout" element={<Logout redirect={"/"}/>} />
                </Route>
                <Route path="/notFound" element={<NotFound />} />
                <Route path="*" element={<Navigate to="/notFound" />} />
            </Route>
        </Routes>
    );
}
