import {useEffect, useState} from "react";
import NonInteractableModal from "../components/non-interactable-modal";

export default function Countdown({onActive, onLogout}: {onActive: () => void, onLogout: () => void}) {
    const [countdown, setCountdown] = useState(90);

    const handleActive = () => {
        onActive();
    }

    const handleLogout = () => {
        onLogout();
    }

    useEffect(() => {
        const countdownInterval = setInterval(() => {
            setCountdown(c => c - 1);
        }, 1000);
        return () => clearInterval(countdownInterval);
    }, []);

    useEffect(() => {
        if (countdown === 0) {
            handleLogout();
        }
    }, [countdown, handleLogout]);

    return (
        <NonInteractableModal>
            <div className="countdownContainer">
                <h2>Still there?</h2>
                <div className="countdownMessage">
                    Logging in you out in <span>{countdown}</span> seconds unless you tell us you're still here.
                </div>
                <div className="countdownButtonContainer">
                    <button onClick={handleActive}>Still Here</button>
                    <button onClick={handleLogout} className="countdownLogoutButton">Logout</button>
                </div>
            </div>
        </NonInteractableModal>
    );
}