import React, {useState} from "react";
import Banner from "../banner/banner";
import {Outlet} from "react-router-dom";
import {HelmetProvider, Helmet} from "react-helmet-async";

let SiteContext = React.createContext<SiteContextType>(null!);

export default function Layout() {
    let siteContext = useInitSiteContext();

    return (
        <HelmetProvider>
            <SiteContext.Provider value={siteContext}>
                <Helmet>
                    <title>Awesomebread Studios Blog</title>
                    <meta name="description" content="The official development blog for Awesomebread Studios" />
                </Helmet>
                <div className="app">
                    <Banner />
                    <Outlet />
                </div>
            </SiteContext.Provider>
        </HelmetProvider>
    );
}

export interface SiteContextType {
    authenticatedSession: boolean;
    updateAuthenticatedSession: any;
}

export function useSiteContext() {
    return React.useContext(SiteContext);
}

function useInitSiteContext(): SiteContextType {
    let [authenticatedSession, setAuthenticatedSession] = useState(false);
    let updateAuthenticatedSession = (authenticatedSession: boolean) => {
        setAuthenticatedSession(authenticatedSession);
    }

    return {
        authenticatedSession,
        updateAuthenticatedSession
    }
}