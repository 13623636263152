import dayjs from "dayjs";
import {PostSummaryApiResource} from "../api/PostApi";
import {useNavigate} from "react-router-dom";

export default function PostSummary(postSummary: PostSummaryApiResource) {
    let navigate = useNavigate();
    let postDate = dayjs(postSummary.postDate).format("MMMM D, YYYY h:mma")

    const handleNavigate = (e: any) => {
        e.preventDefault();
        navigate('/posts/' + postSummary.postId);
    }

    return (
      <div className="postSummary">
          <div className="postSummaryImage">
              <img src={'/images/' + postSummary.image} alt={postSummary.imageAlt} />
          </div>
          <div className="postSummaryContent">
              <div className="firstShape" />
              <div className="secondShape" />
              <h3>{postSummary.title} <span>{postDate}</span></h3>
              <p>{postSummary.summary}</p>
              <a className="button" href={'/posts/' + postSummary.postId} onClick={handleNavigate}>Read More</a>
          </div>
      </div>
    );
}