import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {Fade} from "react-awesome-reveal";

export default function Logout({redirect}: {redirect: string}) {
    let navigate = useNavigate();

    useEffect(() => {
        navigate(redirect);
    });

    return (
        <Fade delay={1500}>
            <div className="logoutError">
                <div>If you are seeing this, logging out failed.</div>
            </div>
        </Fade>
    );
}