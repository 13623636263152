import React, {useEffect, useRef, useState} from "react";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import Login from "./login";
import '../css/auth/secure-resource.css'
import {SessionContextType} from "./session";
import {AuthenticationStatusApiResource, InitContext, Logout as LogoutCall, Renew} from "../api/AuthApi";
import LoadingSpinner from "../components/loading-spinner";
import {useIdleTimer} from "react-idle-timer";
import Countdown from "./countdown";
import {useSiteContext} from "../app/layout";

let SessionContext = React.createContext<SessionContextType>(null!);

export default function SecureResource() {
    let siteContext = useSiteContext();
    let session = useInitSession();
    let navigate = useNavigate();
    let location = useLocation();
    let logoutPath = "/admin/logout";
    const [idle, setIdle] = useState(false);
    const [idleTimer, setIdleTimer] = useState(false);
    const [renewAction, setRenewAction] = useState(false);
    const [logoutAction, setLogoutAction] = useState(false);
    const timeout = useRef<NodeJS.Timeout>();

    const initContext = () => {
        session.updateContextLoading(true);
        console.log("Loading session.")

        InitContext().then(response => {
            session.updateAuthenticated(response.data.csrfToken ? AuthenticationStatusApiResource.Authenticated : AuthenticationStatusApiResource.Pending);
            session.updateCsrfToken(response.data.csrfToken);
            session.updateLastLoginTimestamp(new Date(response.data.lastLogin));
            session.updateTokenExpiresTimestamp(new Date(response.data.tokenExpiresTimestamp));
            siteContext.updateAuthenticatedSession(!!response.data.csrfToken);
        }).catch(response => {

        }).finally(() => {
            session.updateContextLoaded(true);
            session.updateContextLoading(false);
        });
    }

    const renew = () => {
        setRenewAction(true);
        let currentDate = new Date();
        let milliUntilRenew = session.tokenExpiresTimestamp.getTime() - currentDate.getTime() - 10000;
        timeout.current = setTimeout(() => {
            console.log("Renewing token.")
            Renew().then(response => {
                session.updateTokenExpiresTimestamp(new Date(response.data.tokenExpiresTimestamp));
            }).catch(response => {
                console.log("Token renewal failed. Logging out.")
                navigate(logoutPath);
            }).finally(() => {
                setRenewAction(false);
            });
        }, milliUntilRenew);
    }

    const logout = () => {
        LogoutCall().then(() => {
            // Do nothing
        }).catch(() => {

        }).finally(() => {
            session.updateAuthenticated(AuthenticationStatusApiResource.Pending)
            session.updateContextLoaded(false);
            session.updateContextLoading(false);
            siteContext.updateAuthenticatedSession(false);
            if (location.pathname !== logoutPath) {
                navigate(logoutPath);
            }
        })
    }

    const handleIdle = () => {
        console.log("Idle detected.");
        setIdle(true);
    }

    const handleActive = () => {
        console.log("Still active.");
        activate();
        setIdle(false);
    }

    const handleLogout = () => {
        setLogoutAction(true);
        console.log("Logging out.");
        setIdle(false);
        if (timeout.current) {
            clearTimeout(timeout.current);
        }
        logout();
    }

    const { start, reset, pause, activate } = useIdleTimer({
        timeout: 1000 * 60 * 8,
        startManually: true,
        stopOnIdle: true,
        onIdle: handleIdle,
        debounce: 500
    })

    const startTimer = () => {
        console.log("Starting timer.");
        reset();
        start();
        setIdleTimer(true);
    }

    const stopTimer = () => {
        console.log("Stopping timer.");
        pause();
        setIdleTimer(false);
    }

    useEffect(() => {
        if (!session.contextLoaded && !session.contextLoading  && !logoutAction  && location.pathname !== logoutPath) {
            initContext();
        }
        if (session.contextLoaded && !session.contextLoading && session.authenticated === AuthenticationStatusApiResource.Authenticated && !renewAction) {
            renew();
        }
        if (session.authenticated === AuthenticationStatusApiResource.Authenticated && !idleTimer) {
            startTimer();
        }
        if (session.authenticated !== AuthenticationStatusApiResource.Authenticated && idleTimer) {
            stopTimer();
        }
    }, [session]);

    useEffect(() => {
        if (location.pathname === logoutPath && !logoutAction) {
            handleLogout();
        }
    }, [location]);

    return (
        <SessionContext.Provider value={session}>
            <div className="secure">
                {(session.contextLoading || logoutAction) &&
                    <div className="contentLoading">
                        <LoadingSpinner />
                    </div>
                }
                {(session.contextLoaded &&
                  session.authenticated !== AuthenticationStatusApiResource.Authenticated) &&
                    <Login />
                }
                {idle &&
                    <Countdown onActive={handleActive} onLogout={handleLogout} />
                }
                {((session.contextLoaded &&
                  session.authenticated === AuthenticationStatusApiResource.Authenticated &&
                  !logoutAction) || (location.pathname === logoutPath && logoutAction)) &&
                    <Outlet />
                }
            </div>
        </SessionContext.Provider>
    );
}

export function useSession() {
    return React.useContext(SessionContext);
}

function useInitSession(): SessionContextType {
    let [contextLoaded, setContextLoaded] = useState(false);
    let [contextLoading, setContextLoading] = useState(false);
    let [authenticated, setAuthenticated] = useState(AuthenticationStatusApiResource.Pending);
    let [csrfToken, setCsrfToken] = useState('');
    let [lastLoginTimestamp, setLastLoginTimestamp] = useState(new Date());
    let [tokenExpiresTimestamp, setTokenExpiresTimestamp] = useState(new Date());
    let updateContextLoaded = (loaded: boolean) => {
        setContextLoaded(loaded);
    };
    let updateContextLoading = (loading: boolean) => {
        setContextLoading(loading);
    }
    let updateAuthenticated = (auth: AuthenticationStatusApiResource) => {
        setAuthenticated(auth);
    };
    let updateCsrfToken = (csrfToken: string) => {
        setCsrfToken(csrfToken);
    };
    let updateLastLoginTimestamp = (lastLoginTimestamp: Date) => {
        setLastLoginTimestamp(lastLoginTimestamp);
    };
    let updateTokenExpiresTimestamp = (tokenExpiresTimestamp: Date) => {
        setTokenExpiresTimestamp(tokenExpiresTimestamp);
    }

    return {
        contextLoaded,
        contextLoading,
        authenticated,
        csrfToken,
        lastLoginTimestamp,
        tokenExpiresTimestamp,
        updateContextLoaded,
        updateContextLoading,
        updateAuthenticated,
        updateCsrfToken,
        updateLastLoginTimestamp,
        updateTokenExpiresTimestamp
    };
}