import {Fade} from "react-awesome-reveal";
import {useInputBinder} from "../util/binders";
import React, {useState} from "react";
import {Authenticate, AuthenticationStatusApiResource} from "../api/AuthApi";
import {useSession} from "./secure-resource";
import LoadingSpinner from "../components/loading-spinner";

export default function Login() {
    let session = useSession();
    const [loginAction, setLoginAction] = useState(false);
    const {value: username, bind: bindUsername, reset: resetUsername} = useInputBinder('');
    const {value: password, bind: bindPassword, reset: resetPassword} = useInputBinder('');

    const handleLogin = (e: any) => {
        e.preventDefault();
        setLoginAction(true);
        Authenticate({username, password}).then(response => {
            if (response.data.status === AuthenticationStatusApiResource.Authenticated) {
                session.updateContextLoaded(false);
            } else if (response.data.status === AuthenticationStatusApiResource.Invalid_Credentials) {
                setLoginAction(false);
            }
        }).catch(() => {
            setLoginAction(false);
        }).finally(() => {
            resetUsername();
            resetPassword();
        });
    }

    return (
        <>
            {loginAction &&
                    <div className="contentLoading">
                        <LoadingSpinner />
                    </div>
            }
            {!loginAction &&
                <form className="loginContainer">
                    <Fade>
                        <div className="loginControls">
                            <input type="text" {...bindUsername} placeholder="Username" autoFocus disabled={loginAction}/>
                            <input type="password" {...bindPassword} placeholder="Password" disabled={loginAction}/>
                            <button onClick={handleLogin} disabled={loginAction}>Login</button>
                        </div>
                    </Fade>
                </form>
            }
        </>
    );
}