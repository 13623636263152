import "./../css/banner/banner.css"
import {useNavigate} from "react-router-dom";
import {useSiteContext} from "../app/layout";

export default function Banner() {
    let siteContext = useSiteContext();
    let navigate = useNavigate();

    const handleBlog = (e: any) => {
        e.preventDefault();
        navigate("/");
    }

    const handleLogout = (e: any) => {
        e.preventDefault();
        navigate("/admin/logout");
    }

    return (
        <div className="banner">
            <div className="bannerLeft">
                <a className="bannerItem bannerSiteHeader" href="https://www.awesomebreadstudios.com">Awesomebread Studios</a>
            </div>
            <div className="bannerCenter">
                <a className="bannerItem bannerBlogHeader" href="/" onClick={handleBlog}>D E V&nbsp;&nbsp;B L O G</a>
            </div>
            <div className="bannerRight">
                {siteContext.authenticatedSession &&
                    <div className="bannerItem bannerLogoutHeader" onClick={handleLogout}>Logout</div>
                }
            </div>
        </div>
    );
}