import React, {useEffect, useState} from "react";
import {GetAdminPosts, PostSummaryApiResource} from "../../api/PostApi";
import LoadingSpinner from "../../components/loading-spinner";
import AdminPostSummary from "../admin/post-summary";
import {Fade} from "react-awesome-reveal";
import {useNavigate} from "react-router-dom";

export default function AdminPosts() {
    let navigate = useNavigate();
    const [drafts, setDrafts] = useState<PostSummaryApiResource[]>([]);
    const [scheduled, setScheduled] = useState<PostSummaryApiResource[]>([]);
    const [posts, setPosts] = useState<PostSummaryApiResource[]>([]);
    const [postsPage, setPostsPage] = useState(0);
    const [postsLoading, setPostsLoading] = useState(false);
    const [nextPage, setNextPage] = useState(false);

    const fetchPosts = (e:any) => {
        setPostsLoading(true);
        GetAdminPosts({page: postsPage, size: 100}).then((response) => {
            response.data.posts.forEach((postSummary) => {
                if (postSummary.draft) {
                    setDrafts(oldArray => [...oldArray, postSummary]);
                } else if (postSummary.scheduled  && postSummary.scheduledDate && new Date(postSummary.scheduledDate) > new Date()) {
                    setScheduled(oldArray => [...oldArray, postSummary]);
                } else {
                    setPosts(oldArray => [...oldArray, postSummary]);
                }
            });
            if (response.data.links) {
                setPostsPage(postsPage + 1);
                setNextPage(true);
            } else {
                setNextPage(false);
            }
        }).catch(() => {

        }).finally(() => {
            setPostsLoading(false);
        });
    }

    const handleScroll = () => {
        if (Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight - 100) {
            if (nextPage && !postsLoading) {
                fetchPosts(Event);
            }
        }
    }

    const handleNewPost = (e: any) => {
        e.preventDefault();
        navigate("/admin/post");
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, {passive: true})
        fetchPosts(Event);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {postsLoading &&
                <div className="postContentLoading">
                    <LoadingSpinner />
                </div>
            }
            <div className="adminPosts">
                <Fade>
                    <h2>Drafts <button className="adminNewPost" onClick={handleNewPost} >New Post</button></h2>
                    {drafts.map((postSummary, i) => {
                        return <AdminPostSummary {...postSummary} key={i}/>
                    })}
                    <h2>Scheduled</h2>
                    {scheduled.map((postSummary, i) => {
                        return <AdminPostSummary {...postSummary} key={i}/>
                    })}
                    <h2>Released</h2>
                    {posts.map((postSummary, i) => {
                        return <AdminPostSummary {...postSummary} key={i}/>
                    })}
                </Fade>
            </div>
        </>
    );
}