import axios from "axios";
import * as qs from "qs";
import dayjs from "dayjs";

export default axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true
});

export function DateTimeSerializer(params: any) {
    return qs.stringify(params, {serializeDate: function(date: Date) {
            return dayjs(date).format("YYYY-MM-DDTHH:mm");
        }});
}

export function PostHeaders(csrfToken: string) {
    return {'x-csrf-token': csrfToken};
}