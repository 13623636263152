import { AxiosPromise } from "axios";
import Api from "./config/ApiConfig";

export function InitContext(): AxiosPromise<ContextApiResource> {
    return Api.request({method: 'GET', url: uriEncoding`/v1/context/init`});
}

export function Authenticate(credentials: AuthRequestApiResource): AxiosPromise<AuthResponseApiResource> {
    return Api.request({method: 'POST', url: uriEncoding`/v1/auth/auth`, data: credentials});
}

export function Renew(): AxiosPromise<AuthTokenRenewApiResource> {
    return Api.request({method: 'GET', url: uriEncoding`/v1/auth/token/renew`});
}

export function Logout(): AxiosPromise {
    return Api.request({method: 'GET', url: uriEncoding`/v1/auth/logout`});
}

export class ContextApiResource {
    csrfToken: string;
    lastLogin: Date;
    tokenExpiresTimestamp: Date;

    constructor(data: ContextApiResource) {
        this.csrfToken = data.csrfToken;
        this.lastLogin = data.lastLogin;
        this.tokenExpiresTimestamp = data.tokenExpiresTimestamp;
    }
}

export class AuthRequestApiResource {
    username?: string;
    password?: string;

    constructor(data: AuthRequestApiResource) {
        this.username = data.username;
        this.password = data.password;
    }
}

export class AuthResponseApiResource {
    status: AuthenticationStatusApiResource;
    links: LinkApiResource[];

    constructor(data: AuthResponseApiResource) {
        this.status = data.status;
        this.links = data.links;
    }
}

export class AuthTokenRenewApiResource {
    tokenExpiresTimestamp: Date;

    constructor(data: AuthTokenRenewApiResource) {
        this.tokenExpiresTimestamp = data.tokenExpiresTimestamp;
    }
}

export class LinkApiResource {
    href?: string;
    rel?: string;
    type?: HttpMethod;

    constructor(data: LinkApiResource) {
        this.href = data.href;
        this.rel = data.rel;
        this.type = data.type;
    }
}

export enum AuthenticationStatusApiResource {
    Pending = 'PENDING',
    Authenticated = 'AUTHENTICATED',
    Invalid_Credentials = 'INVALID_CREDENTIALS'
}

export enum HttpMethod {
    Get = 'GET',
    Head = 'HEAD',
    Post = 'POST',
    Put = 'PUT',
    Patch = 'PATCH',
    Delete = 'DELETE',
    Options = 'OPTIONS',
    Trace = 'TRACE',
}

function uriEncoding(template: TemplateStringsArray, ...substitutions: any[]): string {
    let result = '';
    for (let i = 0; i < substitutions.length; i++) {
        result += template[i];
        result += encodeURIComponent(substitutions[i]);
    }
    result += template[template.length - 1];
    return result;
}