import {useState} from "react";

export const useInputBinder = (initValue: any) => {
    const [value, setValue] = useState(initValue);

    return {
        value,
        setValue,
        reset: () => setValue(initValue),
        bind: {
            value,
            onChange: (change: any) => {
                setValue(change.target.value);
            }
        }
    }
};

export const useCheckboxBinder = (initValue: any) => {
    const [value, setValue] = useState(initValue);

    return {
        value,
        setValue,
        reset: () => setValue(initValue),
        bind: {
            value,
            onChange: (change: any) => {
                setValue(!value);
            },
            checked: value
        }
    }
}