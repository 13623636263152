import {useEffect, useState} from "react";
import PostSummary from "./post-summary";
import "./../css/posts/posts.css";
import {Fade} from "react-awesome-reveal";
import {GetPosts, PostSummaryApiResource} from "../api/PostApi";
import LoadingSpinner from "../components/loading-spinner";

export default function Posts() {
    const [posts, setPosts] = useState<PostSummaryApiResource[]>([]);
    const [postsPage, setPostsPage] = useState(0);
    const [postsLoading, setPostsLoading] = useState(false);
    const [nextPage, setNextPage] = useState(false);

    const fetchPosts = (e:any) => {
        setPostsLoading(true);
        GetPosts({page: postsPage, size: 10}).then((response) => {
            setPosts(oldArray => [...oldArray, ...response.data.posts]);
            if (response.data.links) {
                setPostsPage(postsPage + 1);
                setNextPage(true);
            } else {
                setNextPage(false);
            }
        }).catch(() => {

        }).finally(() => {
            setPostsLoading(false);
        });
    }

    const handleScroll = () => {
        if (Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight - 100) {
            if (nextPage && !postsLoading) {
                fetchPosts(Event);
            }
        }
    }

    useEffect(() => {
        fetchPosts(Event);
        window.addEventListener('scroll', handleScroll, {passive: true});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
            <div className="posts">
                <Fade cascade damping={.25} duration={500} triggerOnce>
                    {posts.map((postSummary, i) => {
                        return <PostSummary {...postSummary} key={i}/>
                    })}
                </Fade>
                {postsLoading &&
                    <LoadingSpinner />
                }
            </div>
    );
}