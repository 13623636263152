import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import ReactMde from "react-mde";
import ReactMarkdown from "react-markdown";
import "react-mde/lib/styles/css/react-mde-all.css";
import {useCheckboxBinder, useInputBinder} from "../../util/binders";
import dayjs from "dayjs";
import remarkGfm from "remark-gfm";
import hljs from 'highlight.js';
import 'highlight.js/styles/atom-one-dark.css'
import {DeletePost, EditPost, GetAdminPost, NewPost, SaveDraft, DiscardDraft} from "../../api/PostApi";
import LoadingSpinner from "../../components/loading-spinner";
import {useSession} from "../../auth/secure-resource";
import {Fade} from "react-awesome-reveal";
import {ReactComponent as SaveIcon} from "../../icons/floppy-disk-solid.svg";
import {useIdleTimer} from "react-idle-timer";

export default function AdminPost() {
    let {postId} = useParams();
    let session = useSession();
    let navigate = useNavigate();
    const [mode, setMode] = useState('write');
    const [postAction, setPostAction] = useState(false);
    const [loadedFromDraft, setLoadedFromDraft] = useState(false);
    const [draftSaved, setDraftSaved] = useState(false);
    const [saveOnExit, setSaveOnExit] = useState(true);
    const [active, setActive] = useState(true);

    const {value: postTitle, setValue: setPostTitle, bind: bindPostTitle} = useInputBinder("");
    const {value: draftPost, setValue: setDraftPost, bind: bindDraftPost} = useCheckboxBinder(true);
    const {value: scheduledPost, setValue: setScheduledPost, bind: bindScheduledPost} = useCheckboxBinder(false);
    const {value: postScheduledDate, setValue: setPostScheduledDate, bind: bindPostScheduledDate} = useInputBinder(dayjs(new Date()).format("YYYY-MM-DDTHH:mm"));
    const {value: summaryImage, setValue: setSummaryImage, bind: bindSummaryImage} = useInputBinder("");
    const {value: summaryImageAlt, setValue: setSummaryImageAlt, bind: bindSummaryImageAlt} = useInputBinder("");
    const {value: tags, setValue: setTags, bind: bindTags} = useInputBinder("");
    const {value: postDescription, setValue: setPostDescription, bind: bindPostDescription} = useInputBinder("");
    const [postContent, setPostContent] = useState("");

    const showWrite = (e: any) => {
        e.preventDefault();
        setMode('write');
    }

    const showPreview = (e: any) => {
        e.preventDefault();
        setMode('preview');
    }

    const fetchPost = (e:any) => {
        if (postId) {
            setPostAction(true);
            GetAdminPost(postId).then((response) => {
                setPostTitle(response.data.title);
                setDraftPost(response.data.draft);
                setScheduledPost(response.data.scheduled);
                setPostScheduledDate(dayjs(response.data.scheduledDate).format("YYYY-MM-DDTHH:mm"));
                setSummaryImage(response.data.summaryImage);
                setSummaryImageAlt(response.data.summaryImageAlt);
                setTags(response.data.tags);
                setPostContent(response.data.content);
                setPostDescription(response.data.description);
                if (response.data.fromDraft) {
                    showLoadedFromDraft();
                }
            }).catch((response) => {
                if (response.status === '404') {
                    navigate('/notFound');
                }
            }).finally(() => {
                setPostAction(false);
            });
        }
    }

    useEffect(() => {
        fetchPost(Event);
    }, [postId]);

    const savePost = (e: any, exit: boolean) => {
        e.preventDefault();
        if (postId) {
            updatePost(exit);
        } else {
            addPost(exit);
        }
    }

    const addPost = (exit: boolean) => {
        setPostAction(true);
        NewPost({title: postTitle,
            description: postDescription,
            draft: draftPost,
            scheduled: scheduledPost,
            scheduledDate: new Date(postScheduledDate),
            summaryImage: summaryImage,
            summaryImageAlt: summaryImageAlt,
            content: postContent,
            tags: tags}, session.csrfToken).then((response) => {
            if (exit) {
                setSaveOnExit(false);
                navigate('/admin/posts');
            }
        }).catch(() => {

        }).finally(() => {
            setPostAction(false);
        });
    }

    const updatePost = (exit: boolean) => {
        setPostAction(true);
        EditPost({postId: postId,
            title: postTitle,
            description: postDescription,
            draft: draftPost,
            scheduled: scheduledPost,
            scheduledDate: new Date(postScheduledDate),
            summaryImage: summaryImage,
            summaryImageAlt: summaryImageAlt,
            content: postContent,
            tags: tags}, session.csrfToken).then((response) => {
            if (exit) {
                setSaveOnExit(false);
                navigate('/admin/posts');
            } else {
                navigate(`/admin/post/${response.data.postId}`)
            }
        }).catch(() => {

        }).finally(() => {
            setPostAction(false);
        });
    }

    const deletePost = () => {
        if (postId && window.confirm("Are you sure?")) {
            setPostAction(true);
            DeletePost(postId, session.csrfToken).then((response) => {
                setSaveOnExit(false);
                navigate('/admin/posts');
            }).catch(() => {

            }).finally(() => {
                setPostAction(false);
            });
        }
    }

    const saveDraft = (): void => {
        if (saveOnExit && active) {
            SaveDraft({postId: postId ? postId : postTitle,
                title: postTitle,
                description: postDescription,
                draft: draftPost,
                scheduled: scheduledPost,
                scheduledDate: new Date(postScheduledDate),
                summaryImage: summaryImage,
                summaryImageAlt: summaryImageAlt,
                content: postContent,
                tags: tags}, session.csrfToken).then((response) => {
                setDraftSaved(true);
                setInterval((): void => {
                    setDraftSaved(false);
                }, 5000);
                setActive(false);
            }).catch(() => {

            }).finally(() => {

            });
        }
    }

    const showLoadedFromDraft = (): void => {
        setLoadedFromDraft(true);
        setInterval((): void => {
            setLoadedFromDraft(false);
        }, 5000);
    }

    const discardDraft = (): void => {
        if (postTitle) {
            setPostAction(true);
            DiscardDraft(postId!, session.csrfToken).then((response) => {

            }).catch(() => {

            }).finally(() => {
                setPostAction(false);
            });
        }
    }

    const handleIdle = () => {
        console.log("Idle detected, attempting to save draft.");
        saveDraft();
    }

    const handleActive = () => {
        console.log("Active again.")
        activate();
        setActive(true);
    }

    const { activate } = useIdleTimer({
        timeout: 1000 * 15,
        onIdle: handleIdle,
        onActive: handleActive,
        debounce: 500
    });

    //useEffect(() => {
    //    return () => {
    //        saveDraft();
    //    };
    //}, []);

    useEffect(() => {
        if (mode === 'preview') {
            hljs.highlightAll();
        }
    }, [mode]);

    return (
        <div className="adminPost">
            {postAction &&
                <div className="postContentLoading">
                    <LoadingSpinner />
                </div>
            }
            {!postAction &&
                <>
                    <h1>{!postId && "Create Post"}{postId && "Edit Post"}</h1>
                    <div className="adminPostMarkdownControls">
                        <button className={`${mode === 'write' ? "buttonSelected" : ""}`} onClick={showWrite}>Write</button>
                        <button className={`${mode === 'preview' ? "buttonSelected" : ""}`} onClick={showPreview}>Preview</button>
                        {draftSaved &&
                            <Fade className="adminPostMarkdownControlsRightAlign">
                                <div className="adminPostDraftSaved">Draft Saved <SaveIcon/></div>
                            </Fade>
                        }
                        {postId &&
                            <button className={`adminPostDelete ${draftSaved ? "" : "adminPostMarkdownControlsRightAlign"}`} onClick={deletePost} >Delete Post</button>
                        }
                    </div>
                    {loadedFromDraft &&
                        <Fade>
                            <div className="adminPostLoadedFromDraft">
                                <label>Post loaded from draft</label>
                                <button onClick={discardDraft}>Discard Draft</button>
                            </div>
                        </Fade>
                    }
                    {mode === 'write' &&
                        <>
                            <div className="adminPostDetails">
                                <div className="adminPostDetailsRow">
                                    <label>Title</label>
                                    <input type="text" className="adminPostMediumInput" {...bindPostTitle} required/>
                                    <label>Draft</label>
                                    <input type="checkbox" {...bindDraftPost}/>
                                    <label>Schedule</label>
                                    <input type="checkbox" {...bindScheduledPost}/>
                                    <label>Date</label>
                                    <input type="datetime-local" {...bindPostScheduledDate} disabled={!scheduledPost} required/>
                                </div>
                                <div className="adminPostDetailsRow">
                                    <label>Description</label>
                                    <textarea className="adminPostDescription" {...bindPostDescription}>{postDescription}</textarea>
                                </div>
                                <div className="adminPostDetailsRow">
                                    <label>Summary Image</label>
                                    <input type="text" {...bindSummaryImage} required/>
                                    <label>Image Description</label>
                                    <input type="text" className="adminPostMediumInput" {...bindSummaryImageAlt} required/>
                                </div>
                                <div className="adminPostDetailsRow">
                                    <label>Tags</label>
                                    <input type="text" className="adminPostLongInput" {...bindTags} required/>
                                </div>
                            </div>
                            <ReactMde value={postContent} onChange={setPostContent} disablePreview={true} minEditorHeight={325}/>
                        </>}
                    {mode === 'preview' &&
                        <div className="postContent">
                            <ReactMarkdown children={postContent} remarkPlugins={[remarkGfm]} />
                        </div>}
                    <div className="adminPostControls">
                        <button onClick={(e) => savePost(e, true)}>Save & Exit</button>
                        <button onClick={(e) => savePost(e, false)}>Save</button>
                    </div>
                </>
            }
        </div>
    );
}