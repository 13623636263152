import {PostSummaryApiResource} from "../../api/PostApi";
import dayjs from "dayjs";
import {useNavigate} from "react-router-dom";

export default function AdminPostSummary(postSummary: PostSummaryApiResource) {
    let navigate = useNavigate();
    let postDate = dayjs(postSummary.postDate).format("MMMM D, YYYY h:mma")

    const handleNavigate = (e: any) => {
        e.preventDefault();
        navigate('/admin/post/' + postSummary.postId);
    }

    return (
        <a className="adminPostSummary" href={'/admin/post/' + postSummary.postId} onClick={handleNavigate}>
            <div className="adminPostSummaryImage">
                <img src={'/images/' + postSummary.image} alt={postSummary.imageAlt} />
            </div>
            <div className="adminPostSummaryContent">
                <div className="adminFirstShape" />
                <div className="adminSecondShape" />
                <h4>{postSummary.title} {!postSummary.draft && <span>{postDate}</span>}</h4>
                <p>{postSummary.summary}</p>
            </div>
        </a>
    );
}