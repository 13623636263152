import dayjs from "dayjs";
import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import {DiscussionEmbed} from "disqus-react";
import {GetPost, PostApiResource} from "../api/PostApi";
import LoadingSpinner from "../components/loading-spinner";
import hljs from "highlight.js";
import Tags from "./tags";
import {Helmet} from "react-helmet-async"

export default function Post() {
    let navigate = useNavigate();
    let {postId} = useParams();
    const [post, setPost] = useState<PostApiResource>();
    const [postLoading, setPostLoading] = useState(false);

    const fetchPost = (e:any) => {
        if (postId) {
            setPostLoading(true);
            GetPost(postId).then((response) => {
                setPost(response.data);
                hljs.highlightAll();
            }).catch((response) => {
                if (response.status === '404') {
                    navigate('notFound');
                }
            }).finally(() => {
                setPostLoading(false);
            });
        }
    }

    useEffect(() => {
        fetchPost(Event);
    }, [postId]);

    const disqusShortname = 'awesomebreadstudios'
    const disqusConfig = {
        url: 'https://blog.awesomebreadstudios.com/posts/' + postId,
        identifier: postId,
        title: post?.title
    }

    return (
        <div className="post">
            {postLoading &&
                <div className="postContentLoading">
                    <LoadingSpinner />
                </div>
            }
            {post &&
                <>
                    <Helmet>
                        <title>{post.title} - Awesomebread Studios Blog</title>
                        <meta name="description" content={post.description} />
                    </Helmet>
                    <h1>{post.title}</h1>
                    <div className="postMeta">
                        <>
                            By {post.author} on {dayjs(post.postDate).format("MMMM D, YYYY h:mma")}
                            {post.editedDate && post.editedDate !== post.postDate &&
                                <>
                                    &nbsp;- updated {dayjs(post.editedDate).format("MMMM D, YYYY h:mma")}
                                </>
                            }
                        </>
                    </div>
                    <div className="postContent">
                        <ReactMarkdown children={post.content} remarkPlugins={[remarkGfm]} />
                        <Tags tags={post.tags} />
                        <DiscussionEmbed shortname={disqusShortname} config={disqusConfig} />
                    </div>
                </>
            }
        </div>
    );
}